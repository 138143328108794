import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["kindField", "guideForField", "urlField", "fileField"];

	connect() {
		const mediaFormat = document.querySelector("input[name='guide[media_format]']:checked").value;
		this.showAndHideGuideFields(mediaFormat);
	}

	changeMediaFormat(event) {
		const mediaFormat = event.target.value;
		this.showAndHideGuideFields(mediaFormat);
	}

	showAndHideGuideFields(mediaFormat) {
		if (mediaFormat === "url") {
			this.kindFieldTarget.classList.add("hidden");
			this.fileFieldTarget.classList.add("hidden");
			this.urlFieldTarget.classList.remove("hidden");
			this.guideForFieldTarget.classList.remove("hidden");
		} else {
			this.kindFieldTarget.classList.remove("hidden");
			this.fileFieldTarget.classList.remove("hidden");
			this.urlFieldTarget.classList.add("hidden");
			this.guideForFieldTarget.classList.add("hidden");
		}
	}
}
